import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div>{children}</div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`When I had moved to SBU, as a freshmen going to the laundry was
always a dreaded endeavor and that was largely due to the fact that
I never knew if there was a machine available for me. Soon enough I
found out that there was a website available. Although, it was
archaic and didn’t function well on mobile.`}</p>
    <p>{`What began as a side project during my first year turned out to be a very
useful utility for a lot of students at SBU to my surprise. I was always
taken aback by just how many people were using the app. It was the first real
project for me that I developed end to end. I learned quite about what it means to
develop and more importantly `}<em parentName="p">{`maintain`}</em>{` software. Responding to bug reports and
feature reqeuests was the most valuable aspects of this entire project.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      